@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./font/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyrBold';
    src: url('./font/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyrLight';
    src: url('./font/HelveticaNeueCyr-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'TextaAlt';
    src: url('./font/TextaAlt-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'TextaAltMedium';
    src: url('./font/TextaAlt-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'TextaAltBold';
    src: url('./font/TextaAlt-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'PhilosopherBold';
    src: url('./font/Philosopher-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Philosopher';
    src: url('./font/Philosopher-Regular.ttf') format('truetype');
    font-display: swap;
}
